import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  NavAbout,
  CBtn,
  CBtnList,
  CGridCard,
} from "../../../components/_index";

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  console.log(data.markdownRemark);
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        data={{
          title: {
            ja: "各ホテルへのお問合せ",
          },
          img: {
            src: "/assets/images/common/kv.png",
          },
          imgSp: {
            src: "/assets/images/common/kv__sp.png",
          },
        }}
      />
      <CBreadCrumb
        data={{
          parent: [
            {
              label: "三菱地所ホテルズ＆リゾーツについて",
              path: "/about/",
            },
          ],
          current: {
            label: frontmatter?.title.includes("-")
              ? frontmatter?.title.split("-")[0]
              : frontmatter?.title,
          },
        }}
      />
      <div className="l_sect02">
        <LWrap>
          <p className="c_sectLead u_mbMedium">
            各ホテルやザ
            ロイヤルパークホテルズに関するお問合せは、お問合せフォームより承ります。
            <br />
            <span className="u_referenceMark">
              返答までお時間を頂戴する場合がございます。お急ぎの場合はお電話にてお問合せください。
            </span>
          </p>
          <div className="u_mbMedium">
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">仙台ロイヤルパークホテル</p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ロイヤルパークホテル（東京・日本橋）
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">横浜ロイヤルパークホテル（一時休業中）</p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://contact.royalparkhotels.co.jp/yrph/contact?_ifbs-yrph_contact_form=s1_Step1",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </div>
          <section className="u_mbMedium">
            <h2 className="c_headingLv2">
              ザ ロイヤルパークホテル アイコニック
            </h2>
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル アイコニック 東京汐留
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/ic/tokyoshiodome/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル アイコニック 名古屋
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/ic/nagoya/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル アイコニック 京都
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/ic/kyoto/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル アイコニック 大阪御堂筋
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/ic/osakamidosuji/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv2">ザ ロイヤルパークホテル</h2>
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル 東京羽田
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the/tokyohaneda/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル 銀座6丁目
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the/ginza6/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル 京都三条
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the/kyotosanjo/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル 京都梅小路
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the/kyotoumekoji/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル 広島リバーサイド
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the/hiroshimars/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパークホテル 福岡
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the/fukuoka/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv2">ザ ロイヤルパーク キャンバス</h2>
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 札幌大通公園
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 銀座8
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.canvas-ginza8.jp/contact/",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 銀座コリドー
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/ginzacorridor/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 名古屋
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/nagoya/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 京都二条
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/kyotonijo/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 大阪北浜
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/osakakitahama/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 神戸三宮
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/kobesannomiya/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ ロイヤルパーク キャンバス 福岡中洲
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/canvas/fukuokanakasu/about/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv2">ザ クラブ・ロイヤルパークホテルズ</h2>
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ザ クラブ・ロイヤルパークホテルズ
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.royalparkhotels.co.jp/the-club/faq/#contact",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv2">丸ノ内ホテル</h2>
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        丸ノ内ホテル
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.marunouchi-hotel.co.jp/contact/",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv2">ホテルアトールエメラルド宮古島</h2>
            <CGridCard
              col={3}
              data={[
                {
                  content: (
                    <>
                      <p className="u_mb10 u_tac">
                        ホテルアトールエメラルド宮古島
                      </p>
                      <CBtn
                        label="お問合せ"
                        icon="blank"
                        color="bgBlack"
                        link={{
                          href: "https://www.atollemerald.jp/contact/",
                          blank: true,
                        }}
                      />
                    </>
                  ),
                },
              ]}
            />
          </section>
        </LWrap>
      </div>
      <NavAbout />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
